import {
  get,
  post
}
  from '../common/axiosHttp.js'

// 登录接口
export const login = params => post("/login", params);

// 验证码登录
export const code = params => post("/login/sendMessage", params);

// 退出登录
export const logout = params => post("/login/logout", params);

// // 角色列表
export const rolePage = params => get(`/role/page/${page}/${size}`, params);

// // 获取用户菜单树
// export const menuList = params => get("/menu/findTreeAll", params);

// // 角色添加
// export const roleAdd = params => post("/role/add", params);

// // 编辑角色
// export const roleUpdate = params => post("/role/update", params);

// // 删除角色
// export const roleDelete = params => post(`/role/deleteById/${id}`, params);

// // 用户列表
// export const userList = params => get(`/user/page/${page}/${size}`, params);

// // 添加用户
// export const userAdd = params => get("/user/add", params);

// // 编辑用户
// export const userUpdate = params => post("/user/update", params);

// // 删除用户
// export const userDelete = params => post(`/user/deleteById/${id}`, params);

// // 修改用户状态
// export const userUpdateStatus = params => post("/user/updateStatus", params);

// // 报警记录列表
// export const alarmRecordList = params => get(`/alarmRecord/page/${page}/${size}`, params);

// // 站点查询
// export const sitePage = params => get("/site/page", params);

// // 处理报警信息
// export const dispose = params => post("/alarmRecord/dispose", params);

// // 报警记录列表
// export const RecordList = params => get("/alarmRecord/index/findAll", params);

// // 监控档案分页列表
// export const videoList = params => get(`/video/page/${page}/${size}`, params);
